@import '../../../index.scss';

.horizontal-list-item{
    width: 100%;
    padding : 2px 0px;
    border-bottom : 1px solid var(--border-color);
    display: inline-flex;
    img{
        margin-right: 20px;
        width: 50px;
        
    }
}

.icon-container-right{
    justify-self: end;
    svg{
        width: 30px;
    }
}

.release-list{
    display: grid;
    grid-template-columns: auto auto;
    position: relative;
}

.horizontal-list{
    padding-top : 20px;
}


.horizontal-container{
    max-width: inherit;
}

.outer-horizontal-list-grid{
    display: block;
    height: auto;
    width: auto;
}

.horizontal-list-grid{
    position: relative;
    margin-top : 20px;
    width: 100%;
    display: grid;
    overflow-x: hidden;
    display: inline-flex;
}


.grey-icon{
    svg{
        color: var(--background-color-grey);
    }
}

.outer-horizontal-list{
    width: auto;
    position: relative;
    display: block;
    height: auto;
}