.history-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    border: 1px solid #3b3b3b;
    border-radius: 10px;
}
.heading-div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #3b3b3b;
}

.body-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}

.body-div {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btns-div {
    display: flex;
    gap: 10px;
    align-items: center;
}