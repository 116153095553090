@import '../../../index.scss';

.soundplayer-container{
    background: var(--background-color);
    width: 100%;
    z-index: 3;
    height: 59px;
    bottom: 0;
    border-top: 1px solid var(--border-color);

    .player{
        width: 150px;
        margin: auto;
    }
    
    .gif-image{
        max-width: 40px;
        margin: auto;
        position: relative;

        /* Mobile */
        @media screen and (max-width: 450px) {
        width: 30px;
        }

    }
}

.image-container-soundplayer{
        width: 40px;
 /* Mobile */
    @media screen and (max-width: 450px) {
        vertical-align: middle;
        position: relative;
        display: block;
    }

}

.soundplayer{
    display: grid;
    position: relative;
    grid-template-columns: 15% auto 15%;
    padding-top: 2px;

    .player{
        width: 150px;
        height:-webkit-fill-available;
        padding-top : 8px;
        margin: auto;
    }

    /* Table & Small PC */
    @media screen and (max-width: 1048px) {
        grid-template-columns: minmax(150px, 20%) auto minmax(150px, 20%);
    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        grid-template-columns: 100%;
    }
}


.sound-container{
    display: block;
    position: relative;

    .pack{
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
        //height: 6.5vh;
        width: 450px;
        margin : auto;
        padding : 5px 20px;
        padding-top : 7px;
        vertical-align: middle;
        @media screen and (max-width: 450px) {
            width: 100%;
            border: none;
        }

        .text-container{
            width: 150px;
        }
    }
}

.vertical-center{
    vertical-align: middle;
    display: inline-table;
}

.text-container{
    margin : 0px 10px;
    margin-left: 20px;

    h5{
        margin : 0;
    }
}

.tag-container{
    width: 50px;
}

.slider-container{
    height:-webkit-fill-available;
        padding-top : 10px;
    margin : auto;
}