@import '../../../../colors.scss';


.second-page {
    background-color: var(--background-color);
    padding: 50px 20px;

    .container{
        display: grid;
        grid-template-columns: 50% 50%;

        @media screen and (max-width: 920px) {
            grid-template-columns: auto;
        }
    }

    .container-outline{
        @media screen and (max-width: 920px) {
            width: 100% !important;
        }
    }

    .flex-container{
        margin : 10px 0px;
    }

    .centered{
        padding-left : 20px;
    }
    
    img{
        border-radius: 5px;
        width: 600px;
        margin: auto;
        box-shadow: 1px 10px 100px 10px var(--shadow-color);
    }

    .container-outline{
        margin-top : 20px;
        width: min-content;
    }
}

.search-bar-container{
    margin-top : 20px;
  
}

.container {
    position: relative; /* Set position to relative */
    height: auto; /* Set the height of the container */
}
.centered {
    margin: auto;
    @media screen and (max-width: 920px) {
        margin: 40px auto;
        
    }

}