@import '../../../index.scss';


.donwload-tab{
    position: absolute;
    top: 10vh;
    right: 10px;
    display: flex;
    width: 150px;
    z-index: 2;
    height: auto;
    padding: 5px 10px;
    background-color: var(--background-color-grey-2);
    border: 1px solid  var(--border-color);
    border-radius: 5px;

    h5{
        margin-left: 10px;
        margin-top : 2px;
    }
}