@import '../../../index.scss';

.sticky{
    position: sticky;
    top: 0;
    z-index: 2;
}

.container-search-header{
/* Mobile */
    @media screen and (max-width: 450px) {
    }
}

.header-grid{
    display: grid;
    //border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color)-secondary;
    padding : 14px 5px;
    padding-bottom : 15px;
    width: 100%;
    grid-template-columns: auto auto;

    /* Mobile */
    @media screen and (max-width: 450px) {
        grid-template-columns: 100%;
    }
}

.image-avatar{
    width: auto;
    position: relative;
}

.avatar-name{
    margin : 0px 10px;
    vertical-align: middle;
    margin-bottom: 22px;
    font-size: 14px !important;
}

.avatar-box{
    padding: 0 !important;
    background:  var(--box-background) !important;
    margin : auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto 35px;
    border-radius: 5px;
    border : 1px solid var(--border-color);
    height: 33px;
    width: max-content;
    vertical-align: revert;

    h4{
        color: var(--text-color) !important;
        margin : 0 5px;
        margin-top : 5px;
    }
}

.credits-container{
    background-color: var(--background-color);
    position: relative;
    padding : 5px 10px;
    margin : 0 4px;
    border-radius: 5px;
    border : 1px solid var(--border-color);
    height: min-content;
    h4{
        vertical-align: -webkit-baseline-middle;
    }
}

.top-header {
    justify-content: end;
    height: 20px;
    padding-right: 10px;
    display: flex;
    grid-template-columns: auto auto;
    /* Mobile */
}

.user-data-header{

    /* Mobile */
    @media screen and (max-width: 450px) {
        display: none;
    }
}
