
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './colors.scss';


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:   var(--color-placholder);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    var(--color-placholder);
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    var(--color-placholder);
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:   var(--color-placholder);
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    var(--color-placholder);
}

::placeholder { /* Most modern browsers support this now. */
   color:    var(--color-placholder);
}


#root, html{
  background-color: var(--background-color);
}
.align-right{
  float: right
}
.align{
  margin : auto;
  display: block;
}

.text-center{
  text-align: center;
  margin : auto;
}
.small-container{
  margin-top : 5px;
  margin-bottom : 5px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.text-green{
  color : $primary-color;
}

.align-center{
  margin: 0 auto !important; 
  display: block!important; 
  position: relative!important; 
}

.inline{
  display: inline-flex;
}

.tag{
  padding: 0px 10px;
  min-width: max-content;
  height: min-content;
  border-radius: 5px;
  margin: 5px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  margin : 2px;
  color: var(--text-color-secondary);
}

.tag-primary{
  padding: 0px 10px;
  min-width: max-content;
  height: min-content;
  border-radius: 5px;
  margin: 5px;
  background-color: var(--background-color);
  border: 1px solid $primary-color;
  margin : 2px;
  color: $primary-color !important;

  svg{
    color: $primary-color;

  }
}

.tag-outline{
  padding: 0px 10px;
  min-width: max-content;
  height: min-content;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid var(--border-color);
  margin : 2px;
  color: var(--text-color-secondary);
}

.container{
  max-width: auto;
  margin : auto;
  padding : 20px;
}

html{
  position: relative;
  display: block;
  overflow-y: scroll;
  height: auto;
}
body {
  margin: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll !important;
  z-index: 100;
  height: -webkit-fill-available;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-striked{
 text-decoration: line-through 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.margin-container{
  margin : auto;
  margin-top : 40px;
  display: block;
  position: relative;
  @media screen and (max-width: 450px) {
    margin-top : 40px;
    
  }
}

.small-container {
  @media screen and (max-width: 450px) {
    width: 240px;
  }
}

h1{
  font-size: 46px;

  @media screen and (max-width: 450px){
    font-size: 32px;
    max-width: 80%;
    margin: auto;
  }
}

.h1{
  font-size: 46px;
 @media screen and (max-width: 450px){
    font-size: 32px;
  }

}

h2, .h2{
  font-size: 28px;
}
h3{
  font-size: 18px;

}
h4{
  font-size: 14px;

}
h5{
  font-size: 12px;
}
h6{
  font-size: 10px;
}

label{
  font-size: 12px;  
}

span{
  font-size: 12px;  
}

input{
  font-size: 12px;
}

h1,h2,h3{
  line-height: normal;
  color: var(--text-color);
  font-family: $bold-font;
}

.text-bold{
  font-family: $bold-font !important;
}

.title{
  font-family: $title-font;
}

.text-thin{
  font-family: var(--main-font);
}

h5,h6,body,p{
  font-family: $main-font;
  color: var(--text-color);
}

input, span, a, div, label{
  font-family: $main-font !important;
  color: var(--text-color);
}

.text-primary{
  color : $primary-color !important;
}

.text-primary{
  color : $secondary-color;
}

input{
  color : var(--text-color) ;
  background-color: var(--input-background);
}

.text-black{
  color : var(--text-color) !important;
}

.text-left{
 text-align: left;
}

.text-grey{
  color : var(--text-color-secondary) !important;
}

header{
  background-color:$primary-color;
}

.align-right{
  justify-content: end;
}
.align-left{
  justify-content: start;
}

.text-right{
  text-align: right;
  float: right;
}

.btn {
  background-color: var(--button-color);
  border-radius: 5px;
  width: fit-content;
  padding: 5px 10px;
  width: max-content;

  cursor:pointer;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  display: block;
  color: var(--background-color);
  transition: transform 0.5s;

  &:hover { 
    opacity: 0.6; 
  }

  h1,h2,h3,h4,h5,h6,p,span{
    color :var(--text-color-inverted);
  }
}

.full-width{
  width: 100%;
}

.text-red{
  color: $red-color;
}

.flex{
  display: flex;
}
.icon-small{
  transform: scale(0.5);
}

.btn-secondary{
  background: $red-color;
  h1,h2,h3,h4,h5,h6,p,span, label{
    color:var(--text-color-inverted);
  }
}

.btn-primary{
  background: $primary-color;
  h1,h2,h3,h4,h5,h6,p,span, label{
    color:var(--text-color-inverted);
  }
}

.btn-outlined{
  border : 1px solid var(--border-color);
  background-color: var(--background-color);
  h1,h2,h3,h4,h5,h6,p,span{
    color:var(--text-color);
  }
}

a, button{
  transition: transform 0.5s;
  &:hover { 
    transition: transform 0.5s;
    opacity: 80%; 
  }
}

.text-cont{
    padding: 10px;
  display: block;
  color :  var(--grey-color);
  position: relative;
    background-color:var(--background-color-grey-2);
}

.text-btn{
   transition: transform 0.5s;
    padding: 10px;
  display: block;
  position: relative;
  &:hover { 
    transition: transform 0.5s;
    background-color:var(--background-color-grey-3);
  }
}

.arrow {
  pointer-events: none;
}
 
.arrow__path {
  fill: transparent;
  stroke-dasharray: 4 2;
}
 
.arrow__head line {
  stroke-width: 1px;
}

.margin-text-landing{
  margin : 0px auto;
  margin-bottom: 10px;
}


.close-button{

}

.absolute-scrollable-pack{
  height: auto;
  padding-bottom : 10px;
  @media screen and (max-width: 450px) {
     height: auto;
  }
}

.absolute-scrollable-sounds{
  height: auto;
  padding-bottom : 10px;
  @media screen and (max-width: 450px) {
     height: auto;
  }
}
.absolute-scrollable-item{
   overflow: scroll;
    display: block;
    padding: 0;
    width: -webkit-fill-available;
    position: relative;
    height: 100%;
    .inside{
      padding : 0px 20px;
      position: relative;
      width: 100%;
      padding-bottom : 10px;
      overflow: scroll;
      height: inherit;
    }
  }

.ruby{
  display: block;
}

.blue-text{
  color : rgb(130, 130, 255);
}


.outside-container{
  height: 95vh;
  display: table; width:100%;
}

.inside-container{
   display: table-cell; vertical-align: middle;
}


/* Tablet */
@media screen and (max-width: 1500px) {
  .no-tablet {
    display: none;
  }
}

.flex-container{
  display: flex;
  height: 100%;
  flex-direction: row;
}

.margin-btn{
  margin : 0px 10px;
}

.text-max{
  min-width: max-content;
}


.mobile-only{
  display: none;
  @media screen and (max-width: 450px) {
   display: flex !important;
  }
}


.mobile-column{
  @media screen and (max-width: 450px) {
   flex-direction : column !important;
  }
}




.not-mobile{
  @media screen and (max-width: 450px) {
   display: none !important;
  }
}


.box {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.box.show {
  animation: slideIn 0.5s ease-in-out;
  opacity: 1;
}

.box.hide {
  animation: fadeOut 0.5s ease-in-out;
  opacity: 0;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}