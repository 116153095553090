@import '../../../index.scss';

.footer-container{
    width: 100%;
    height: auto;
    position: relative;
    display: block;
    padding: 0px 10px;
    background-color: var(--background-color);

    .footer-total{
        display: grid;
        max-width: 300px;
        margin: 15px auto;

        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        h4{
            margin-top : 6px;
        }

        img{
            margin : auto;
            width: 30px;
        }
    }
}

.cards-container{
    display: block;
    position: relative;
    height: auto;
    vertical-align: middle;
    float: inline-end;
    margin-top: 10px;
}