@import '../../../../index.scss';

.pack-grid{
    position: relative;
    display: block;
    padding-bottom : 30px;
}

.image-pack{

}

.container-sounds-in-pack{
    height: calc(100vh - 160px);
    overflow: scroll;
    width: 100%;

    @media screen and (max-width: 450px) {
        height: calc(100vh - 60px - 55px - 130px);
    }
}

.pack-container-description{
    margin-top : 20px;
    padding: 0;
}

.text-pack{
    margin-left: 20px;
    display: flex;
    width: -webkit-fill-available;
    flex-direction: column;
    @media screen and (max-width: 450px) {
        margin : 10px ;
    }
}

.container-buypack{
 @media screen and (max-width: 450px) {
        margin-top : 10px ;
    }
}

.buy-container{
    display: inline-block;
    align-self: flex-end;
    width: auto;
}

.tags-buy-container{
    display: inline-block;
    align-self: flex-end;
    margin-bottom: 0px;
    width: auto;

    @media screen and (max-width: 450px) {
         display: block;
        height: max-content;
        width: auto;
        overflow-x: hidden;
    }
}

.pack-page{
    margin : 10px;
    padding: 10px;
}

.list-sounds{
    border-top : 1px solid var(--border-color);
    margin-top : 30px;
    padding-top : 20px
}
