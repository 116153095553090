
/* myComponent.css */

.myBubbleUI {
	width: 100%;
	max-width: 1500px;
	height: 650px;
    margin: auto;
}

.child {
	width: 100%;
	height: 100%;
}