.main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.pro-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #3b3b3b;
    border-radius: 10px;
    width: 60%;
    padding: 20px 15px;
}

.upgrade-button{
    padding: 8px 12px;
    background: linear-gradient(90deg, #0fa2cf, #4134ff, #4e9eff, #037bd0);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.promp-textarea {
    width: 60%;
    border: 1px solid #3b3b3b;
    min-height: 100px;
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
}

.duration-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    background-color: transparent;
}

.duration-input {
    background-color: transparent;
    border: 1px solid #3b3b3b;
    border-radius: 10px;
    padding: 10px;

}

.error-div {
    padding: 3px;
    background-color: red;

}

.generate-btn {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e9eff;
    padding: 10px;
    margin: 0px;
    gap: 10px;
    border-radius: 10px;
}

.generate-btn-disabled {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e9eff;
    padding: 10px;
    margin: 0px;
    gap: 10px;
    border-radius: 10px;
    cursor: not-allowed;
}