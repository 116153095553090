.page{
    padding: 20px;
    height: 82vh;
    width: 100%;
    display: block;
    overflow-y: scroll;
    position: relative;

    @media screen and (max-width: 450px) {
        height: 66vh;

    }
    .pack-container{
        @media screen and (max-width: 450px) {
            grid-template-rows: 150px auto auto;
            width: 160px !important;
            height: 230px;
            margin : auto;
        }
    }
}


