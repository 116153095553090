@import '../../../index.scss';


.right-bar{
    overflow: auto;
    height: auto;
    // display: grid;
    background-color : var(--background-color)-secondary;
    // grid-template-rows: minmax(auto, 60px) minmax(auto, 10px) minmax(auto, 60px) auto;
    max-height: max-content;
    @media screen and (max-width: 450px) {
        grid-template-rows: minmax(auto, 60px) minmax(auto, 60px);
    }
}

.main-page-outer{

}

.container-outline{
    border-radius: $border-radius;
    border : 1px solid var(--border-color);
    padding: 20px;
}

.react-swipeable-view-container{
    max-height: 100%;
}

.main-page{
    grid-template-columns: minmax(auto, 180px) auto;
    display: grid;
    height: calc(100vh - 60px); 
    background-color: var(--background-color)-secondary;

    /* PC Mobile & Tablet */
    @media screen and (max-width: 1200px) {
        grid-template-columns: minmax(auto, 60px) auto;
        height: calc(100vh - 60px); 
    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        grid-template-rows: min-content;
        grid-template-columns: none;
    }
}

.container-search{
    padding : 0px;
    margin-left: 10px;
    position: relative;
    height: 30px;
    justify-content: start;

 /* Mobile */
    @media screen and (max-width: 450px) {
        margin : auto 5px;
    }
    
}