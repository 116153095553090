@import '../../../index.scss';

.left-bar{
    background-color: var(--background-color);
    width: auto;
    padding-top : 20px;
    border-radius: 0px 5px 0px 0px;
    border-right: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);

    @media screen and (max-width: 450px) {
        width: auto;
        padding-top: 0;
        height: min-content;
    }

}

.isActive{
    border-left : 2px solid $primary-color;
    color : $primary-color !important;
    background-color: var(--background-color-grey-2);
    border-radius: 0px 5px 5px 0px;
    h4{
        color : $primary-color !important;
    }

    @media screen and (max-width: 450px) {
        border-left : 2px solid $primary-color;
        border-top : 2px solid $primary-color;
        border-right : 2px solid $primary-color;
        padding-top: 0;
        border-radius: 5px 5px 0px 0px;
        height: min-content;
    }
}


.link-container{
    width: 100%;
}

.title-leftBar{
    margin : 0px 0px;
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    height: min-content;
    cursor: pointer;
    color : var(--text-color);
    h4{
        margin-top : 2px;
        color : var(--text-color);
    }
}

.container-box-left-bar{
    display: grid;
    grid-template-rows: auto;
    padding: 20px 0px;
    padding-right: 10px;

    @media screen and (max-width: 450px) {
        grid-template-columns: auto auto auto auto auto !important;
        grid-template-rows: none;
        padding : 0;
        padding-top : 15px;
    }

}

.left-bar-icon{
    svg{
        transform: scale(0.8);
    }
}

.logo-container{
    //border-bottom : 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4.5px;
    img{
        margin : auto;
        display: block;
        position: relative;
        width: 50px;
    }

    /* Mobile  */
    @media screen and (max-width: 450px) {
        display: grid;
        padding: 10px 5px;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        img{
                display: block;
                margin-left: 10px;
                position: relative;
                width: 40px;
            }

    }

 /* Mobile  */
    @media screen and (max-width: 350px) {
        img{
                display: none;
          
            }

    }
}


/* Small PC */
@media screen and (max-width: 1200px) {
    .leftbar-text{
        display: none;
    }
    .left-bar-icon{
        display: block !important;
        margin : auto;
        position: relative;
        align-items: center;
    svg{
        transform: scale(1);
    }
    }
    .icon-leftBar{
        display: block !important;
        position: relative;
        margin: auto;
    }
}


/* Tablet */
@media screen and (max-width: 768px) {
    .leftbar-text{
        display: none;
    }
    .left-bar-icon{
        display: block !important;
        margin : auto;
        position: relative;
        align-items: center;
    }
    .icon-leftBar{
        display: block !important;
        position: relative;
        margin: auto;
    }
}


/* Mobile */
@media screen and (max-width: 450px) {
   
   
}
