
@import '../../../../index.scss';


.modal-back{

position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  height: 100%;
  width: 100vw;
}

.showModal{
    display: block !important;
}

.hideModal{
    display: none !important;
}


.div-multiSelectBody{
    background-color: var(--background-color);
    position: absolute;
    margin-top:  10px;
    width: auto;
    border-radius: 5px;
    height: min-content;
    border : 1px solid var(--background-color-grey);
    min-width: 180px;
    z-index: 3;
}

.icon-multiSelect{
    width: auto;
}

.selectorMultiSelect{
    margin: 0;
    padding :10px;
    z-index: 2;
    height: 25px;
    h5,h6 {
        margin : 0;
        margin-left: 5px;
    }
    .align{
        vertical-align: middle;
    }
}

.clear-box-container{
    border-top: 1px solid var(--background-color-grey);
    padding : 10px 10px;
}

.children-container{
    overflow-y: scroll;
    height: auto;
    max-height: 300px;
}

.activeButton{
    background-color: $primary-color !important;
    h5{
        color :var(--background-color) !important;
    }
    .icon-multiSelect{
        color :var(--background-color) !important;
    }
}
