@import '../../../../colors.scss';


.video-container {
    width: 90%;
    margin: 20px auto;
    background-color: var(--primary-color);
    border-radius: $border-radius;
    overflow: hidden;
    position: relative; /* Necessary for absolute positioning */
    padding-top: 56.25%; /* 16:9 Aspect Ratio (height/width = 9/16 = 0.5625) */
}

.video-container > div {
    position: absolute; /* Position ReactPlayer absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.btn-animation{
    margin : auto;
    margin-top : 10px;
    padding : 10px 20px !important;
    width: 350px !important;
}