@import '../../../index.scss';

.searchInputWrapper {
    position: relative;
    border: none !important;
}

.search-container{
    width: 350px;

    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 100%;
    }
}
  
.searchInput {
    height: 2rem;
    width: -webkit-fill-available;
    margin: auto;
    border: none;
    width: 100%;
    padding: 1px 20px;
    border-radius: 5px;
    transition: transform 0.1s ease-in-out;
}

/* hide the placeholder text on focus */
:focus::placeholder {
    text-indent: -999px
}

.searchInput:focus {
    outline: none;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
}

.tags-container{
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;

    h4{
        margin : 5px;
    }

}

.tags-container-child{
    margin : auto;
    width: inherit;
    display: inline-flex;
}

.tag-removable-btn{
    border : 2px solid var(--text-color-secondary)!important;
    margin : 5px;
    padding: 5px !important;
    float: left;
}

.btn-clickable{
    background-color: var(--background-color);
}

