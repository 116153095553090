@import '../../../../index.scss';

.subscription-box{
    position: absolute;
    height: auto;
    background: var(--background-color);
    width: 100%;
    left: 0;
    top : 0;
    padding: 20px;
    padding-top : 50px;
    overflow: scroll;
    height: 100vh;
    border-radius: 5px;
    z-index: 10;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 450px) {
        display: block !important;
        .closeIcon{
            top : 2%;
        }
    }       
}

.subscription-discount-box{
    width: fit-content;
    margin: auto;
    margin-top : 10px;
}

.closeIcon{
    position: absolute;
    top : 6%;
    width: auto;
    height: 50px;
    right: 5%;
}

.outer-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-div{
  text-align: center;
}
.stripe-img{
    width: 150px;
    margin: auto;
margin-top : 20px;
}
.inline-items{
    align-items: center;
    display: inline-flex;
    margin-top: 40px;
    justify-content: center;


    @media screen and (max-width: 450px) {
        display: block;
    }
}

.best-deal{
    @media screen and (max-width: 450px) {
        width: auto;
    }
}

.description-container{
    margin-top: 20px;
}

.card-container-absolute{
    position:absolute;
    top:0;right:0;left:0;bottom:0;
    background-color:rgba(0,0,0,0.8);
    z-index:2;
}

.best-dealtext{
    margin-bottom : 10px
}

.subscription-card{
    border : 1px solid var(--border-color);
    padding : 20px;
    width: 350px ;
    text-align: center;
    height: 100px;
    border-radius: 5px;
    display: inline-table;
    margin-right: 10px;
    position: relative;
    .sub-container{
        display: inline-flex;
        height: 100%;
        flex-direction: row;
        
    }

    .btn-subscribe{
        display: grid;
        width: min-content;
        grid-template-columns: 30px auto;
        padding : 2.5px;
        text-align: center;
        margin: auto;
        .icon-desc{
            margin-right: 5px;
            margin-top : -2px;
            color: var(--background-color) !important;
        }
    }

    h2{
        margin-top : 2px;
    }

    img{
        height: 30px;
    }

    @media screen and (max-width: 450px) {
        margin: 10px auto;
    }
}

.icon{
    transform: scale(1.5);
margin-top : 10px;
    margin-right: 15px;

}

.tag-container-price{
    margin-bottom: 5px;
}

.price-container{
    position: relative;
    display: block;
    align-items: center;
    .decimal{
    }
}

.tag-cont{
    font-size: 10px !important;
    margin-left: 5px !important;
    margin-top: 5px !important;
}
.icon-desc{
    margin-right: 5px;
    transform: scale(0.8);
}

.span-desc{
    font-size: 10px;
    margin-left: 4px;
    color :  var(--grey-color);
}

.btn-sub{
    margin: auto;
    display: block;
    position: relative;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 100% !important;
}

.text-desc{
    margin-top: 2px;
}

.promotion-tag{

    h4, span{
        color : $secondary-color;
    }
    svg{
        color : $secondary-color;
    }
}