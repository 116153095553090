@import '../../../../index.scss';

.container-input {
    margin:5px;
    background-color:var(--background-color);
    border-radius:4px;
    border:1px solid var(--border-color);
    overflow:auto;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
}

.container-input-max{
    margin:5px;
    background-color: var(--background-color);
    border-radius:4px;
    border:1px solid var(--border-color);
    overflow:auto;
    width: 100px;
    height: 30px;
    padding: 0;
    cursor: pointer;
}

.container-input:hover {
    background: var(--background-color-grey);
}

.container-input label {
    width:4.0em;
}

.container-input label span {
    text-align:center;
    padding: 5px 2px;
    cursor: pointer;
    display:block;
}

.container-input label input {
    display: none;
    position:absolute;
    top:-20px;
    font-size: 12px;

}

.container-input input:checked + span {
    background-color:$secondary-color;
    color:var(--background-color);

    height: max-content;
}

.grid-container-key{
    display: grid;
    grid-template-columns: 36px 36px 36px 36px 36px 36px 36px 36px;    

}

.key-container{
    position: relative;
    display: block;
    padding: 10px;
    width: 280px;
    height: inherit;
}
