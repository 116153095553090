@import '../../../../colors.scss';



.sd{
    height: max-content;
}


.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: min-content;
    margin: auto;
    gap: 1rem;
}

.pricing-page{
    min-height: 100vh;
    position: relative;
    display: grid;
    grid-template-rows: 50px auto auto 50px;


    .text-container{
        margin-top : 50px;
        padding: 20px 20%;
        margin-bottom : 0px;
        .text-grey{
            margin-top : 10px;
            max-width: 400px;
        }

        .text-primary{
        }
    }
}