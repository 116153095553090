
@import '../../../../colors.scss';

.forthpage{
    padding: 50px 20px;
    margin : auto;
}

.container-solutions{
    display: grid;
    width: min-content;
    grid-template-columns: auto auto auto auto;

    @media screen and (max-width: 1200px) {
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 760px) {
        grid-template-columns: auto;
    }

}

.solutions-container{
    height: 140px;
    width: 300px;
    background-color: var(--background-color-grey-3);
    margin : 5px;
    h3{
        margin-bottom : 10px;    
    }
    .flex-container{
        height: min-content;
    }
    svg{
        color : $primary-color;
        margin-top : 2px;
        margin-right: 10px;
    }
}