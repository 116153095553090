@import '../../../index.scss';


.error-tab{
    position: absolute;
    bottom: 6vh;
    right: 1vh;
    display: block;
    width: 270px;
    z-index: 2;

    height: auto;
    padding: 5px 10px;
    background-color: var(--background-color-grey-2);
    border: 1px solid  var(--background-color-grey-3);
    border-radius: 5px;

    h4{
        margin-left: 10px;
        margin-top : 2px;
    }

    .content{
        margin-top : 10px;
    }
}