@import '../../../index.scss';


.card-container-small{
   
}

.container-card-absolute-small{
    padding: 0px;
}

canvas{
    margin-top: -15px;
}

.id-container{
    margin: 0;
    margin-left: 10px;
}

.left-card{
}

.right-card{
   
}

.container-card-absolute{
}

.credits{
    display: block;
    font-size: small;
    position: absolute;
}

.align-left{
    
}


.icons-container{
   display: flex;
position: relative;
   align-items: end;
   justify-content: end;
}

.list-name{
    max-width: fit-content;    
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.25rem;
}

.name{
    min-width: max-content;
}
.list-tags{
    max-width: fit-content;    
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.25rem;

}

.gradient-overlay{
    position: absolute;
    width: 20px;
    height: 100%;
    left:-20px;
    bottom: 0;
    background: linear-gradient(to right, transparent, inherit);
}

.wave-container{
    padding-top: 10px;
    position: relative;
    height: 50px;
}

.list-tag{
  font-size: 12px;
  min-width: max-content;
  height: min-content;
  border-radius: 5px;
  margin : 2px;
  color: var(--text-color-terciary);
}

.taglist-text{
    display: block;
    position: relative;
    text-align: center;
    margin-top: 17px;
}

.taglist-container{
    position: relative;
    height: 100%;
}

.hover-structure{
    &:hover { 
        transition: transform 0.5s;
        background: linear-gradient(to right, var(--box-background) 0%, var(--background-color) 20%, var(--background-color) 80%,  var(--box-background) 100%);
    }
}

.list-active{
     background-color: var(--background-color) !important;
}

.list-structure{
    display: grid;
    background-color: var(--background-color)-secondary;
    height: inherit;
    width: 100%;
    align-items: center;
    grid-auto-rows: min-content;
    --pack-size: 36px;
    border : 0px;
    grid-template-columns: 36px 56px minmax(1px, 3fr) minmax(100px,1.6fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(210px,1fr);    
    transition: transform 0.5s;

    /* Small PC */
    @media screen and (max-width: 1200px) {
        grid-template-columns: 36px 56px minmax(1px, 3fr) minmax(100px,1.6fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(100px,1fr);    
        padding-right: 10px;

    }

    /* Tablet */
    @media screen and (max-width: 768px) {
        .wave-container{
            display: none;
        }
        grid-template-columns: 36px 56px minmax(200px, 3fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(70px,.2fr) minmax(40px,1fr);    

    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        .taglist-container{
            display: none;
        }
        .icons-container{
            display: inline-flex;

        }
        grid-template-columns: 36px 56px minmax(80px, 3fr) minmax(120px,1fr);    
    }
}

.favorite-icon{
    svg{
        color: $primary-color !important;
    }
}

.MuiTooltip-tooltip{
  font-family: $main-font !important;
  color : $primary-color;
}
