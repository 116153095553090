@import '../../../../colors.scss';

.testimonials{
    padding: 20px;

    .container-test{
        @media screen and (max-width: 920px) {
            display: none;
        }
    }

    .text-grey{
        margin-top : 10px;
    }
    svg{
        color : var(--text-color-terciary);
        margin-top :5px;
        width: 20px;
        margin-right: 10px;
    }
 
    .tag-outline{
        margin : 5px 0px;
        width: min-content;
    }

    .lf{
        background-color: var(--background-color-grey-2);
        padding: 0px;
        display: grid;
        grid-template-columns: minmax(150px, 150px) auto;
        @media screen and (max-width: 520px) {
            grid-template-columns: 40% 60%;
            a{display: none;}
            h2{font-size: 20px;}
            img{
                width: auto;
            }
        }
        img{
            vertical-align: middle;
        }

        .text-grey{
            margin-top : 0px;
        }


    }
    .text-container{
        margin : 0;
        margin-top : 5px;
        margin-left: 20px;

    }
    .flex-testimonials{
        display: grid;
        grid-template-columns: auto auto auto;
        margin: 20px 0;
    }

    .testimonial{
        margin-top : 20px;
    }
}

.testimonial-container{
    min-height: 300px;
    background-color: var(--background-color-grey-3);
    margin: 20px;
    img{
        border-radius: 5px; 
        width: 150px;
        height: auto;
        display: block;
    }
}