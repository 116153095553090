@import '../../../index.scss';

.btn-animation{
    background: linear-gradient(90deg, 
    #0fa2cf, 
    #4134ff, 
    #4e9eff,
    #037bd0);
    padding: 7px !important;
    width: 180px !important;
    text-align: center;
    height: fit-content;
    z-index: 1;
    background-size: 400%;
    animation: animate 5s linear infinite;
}


.offer-time{
    // position: absolute;
    width: 160px;
    margin-left: 10px;
    // margin-top: 30px;
    padding-top : 2px;
    text-align: center;
    height: 30px;
    text-align: center;
    z-index: 0;
    background-color: var(--background-color);
    border : 1px solid var(--border-color);
    border-radius: 5px;
}
/* Keyframes doing the animation */
/*  If you want to make it animate faster, make the "5s" less -(seconds)  */
@keyframes animate {
0% {
    background-position: 0%;
}
100% {
    background-position: 200%;
}
}
