@import '../../../../../colors.scss';


.sounds-container-promo{
    max-width: 700px;
    margin: auto;
    margin-top : 40px;

    h2{
        margin-bottom: 10px;
        @media screen and (max-width: 520px) {
            font-size : 24px;
            max-width: 90%;
    }}
}


.blur-container{
      /* Use filter to add blur effect */
    background: linear-gradient(rgba(255, 255, 255, 0), $primary-color);
    position: absolute;
    z-index: 10;
    margin-top : -10px;
    width:  700px;
    height: 510px;
    border-radius: $border-radius;
    border : 1px solid var(--border-color);


}

.promo-sounds{
    // height: 510px;
    margin-top : 10px;
    background-color: var(--background-color-grey-2);
    padding: 10px 0px;
    border : 1px solid var(--border-color);
    display: block;
    overflow: hidden;
    border-radius: $border-radius;
    margin-bottom : 20px;
}