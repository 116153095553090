@import '../../../index.scss';


.blog-list{
    padding: 40px 0px;
}

.blog-container{
    padding: 30px 20px;
    width: 90%; margin : auto;

    .text-grey{
        margin-top : 5px !important;   
        margin-bottom : 5px !important;
    }
}


.blog-post{
    width: 300px;
    height: 400px;
    display: inline-grid;
    overflow: hidden;
    margin : 10px;
    border: 1px solid var(--border-color);
    border-radius: $border-radius;
    background-color: var(--background-color);
    img{
        width: 100%;
        height: auto;
    }

    h3,h4, h5{
        padding: 10px 20px;
    }

}
