@import '../../../index.scss';


.modal-subscribe{
    padding: 40px;
    max-width: 500px;
    padding-top: 50px;
    img{
        width: 200px;
        margin: auto;
        border-radius: $border-radius;
        margin-top : 20px;
        margin-bottom : 10px;
    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 100%;
        padding: 20px;
        padding-top : 40px;
        margin: auto;

    }
}



.tag-container-subscribe-modal{
    margin: auto;
    position: relative;
    display: flex;
    svg{
        color: $primary-color;
        margin-top : 0px;
    }
}

.tag-container-sub{
    margin-bottom : 10px;
}

.container-tags-subscription{
    margin-top : 20px;
    margin-bottom : 30px;
    h5{
        margin-left: 30px;
        max-width: auto;
    }
}

.btn-modal{
    padding : 10px 20px !important;
    max-width: fit-content;
}