@import '../../../../colors.scss';

.header-landing{
    background-color: var(--background-color);
    width: 100%;

    padding : 10px 40px;
    display: grid;
    grid-template-columns: minmax(33%, 33%) 34% 33%;

    .link{
        @media screen and (max-width: 920px) {
           display: none;
        }
    }
    img{
        height: 50px;
        margin: auto;
    }

    .vertical-container {
        display: flex;
        align-items: center; /* Vertically centers items */
    }

    .btn{
        margin-left: 20px;
    }
}

.vertical-container {
    display: flex;
    align-items: center; /* Vertically centers items */
}


.link-header{
    margin : 0px 10px;
    color: var(--text-color-secondary);
}
.text-right{
    text-align: right;
    justify-content: flex-end;
}