@import '../../../index.scss';


.pack-container-outer{
    width: min-content;   
    background-color : var(--box-background-color);
    border : 1px solid var(--border-color);
    height: auto;
    border-radius: $border-radius;
    margin: auto;
    display: inline-block;
    padding: 0px !important;
    margin: 10px;
    /* Mobile */
    @media screen and (max-width: 450px) {
        display: initial;
    }
}

.pack-container{
    background-color: transparent !important;
    width: 200px !important;
    height: 280px;
    padding : 0px !important;
    border-radius: 5px;
    display: grid !important;
    grid-template-rows: 200px auto auto;


    img{
        width: inherit;    
        margin-top : 0;
        border-radius: $border-radius !important;
        margin-left: 0;
    }

    .text-container{
        margin: 5px;
        padding : 10px;
        margin-top: 10px;
    }
    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 80% !important;
        height: 200%;
        margin: auto;
        grid-template-rows: auto auto auto;

        img{
            width: 100%;
            margin-top : 0px;
            margin-left: 0px;
        }
    }
}
