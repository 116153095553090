@import '../../../index.scss';

.modal-box{
    background-color: var(--background-color);
    position: absolute;
    top:  60px;
    box-shadow: 10px 1px 100px 1px var(--shadow-color);
    width: max-content;
    border-radius: 5px;
    overflow: hidden;
    height: min-content;
    border : 1px solid var(--background-color-grey);
    min-width: 100px;
    z-index: 10;
}

.modal-middle{
    background-color: var(--background-color);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: auto;
    border-radius: 5px;
    height: auto;
    border : 1px solid var(--background-color-grey);
    min-width: 100px;
    z-index: 10;

    .closeIcon{
        top : 3% !important;
    }

    /* Mobile */
    @media screen and (max-width: 450px) {
        width: 100%;
        height: 100vh;
        overflow: scroll;
        margin: auto;

    }
}


.background-modal{
    height: 100vh;
    top: 0;
    position: absolute;
    opacity: 0.8;
    width: 100%;
    z-index: 3;
    background: black
}