@font-face {
  font-family: 'Righteous';
  src: url("./assets/fonts/Righteous-Regular.ttf");
}
@font-face {
  font-family: 'Righteous-2';
  font-weight: 600;
  src: url("./assets/fonts/Righteous-Regular.ttf");
}

@font-face {
  font-family: 'Poppins';
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

/* Fonts */
$main-font : "Poppins";
$bold-font : "Poppins-Bold";
$title-font : "Righteous";

/* Main Colors */
$primary-color : #6778ff;
$secondary-color : #6778ff;
$red-color : rgb(193, 23, 23);
$third-color : #ffa735;
$border-radius : 5px;
$color-mode : #000000;


$themes: (
  light: (
    color-mode : "light",
    main-font : $main-font,
    bold-font : $bold-font,
    title-font : $title-font,
    primary-color : $primary-color,
    secondary-color : $secondary-color,
    red-color : $red-color,
    third-color : $third-color,
    border-radius : $border-radius,
    /* Light mode */
    dark-color : #333333,
    background-color : #ffffff,
    background-color-secondary : #f8f8f8,
    background-color-inverse : #727272,
    background-color-inverse-2 : #505050,
    background-color-grey : #b7b7b7,
    background-color-grey-2 : #f9f9f9,
    background-color-grey-3 : #e6e6e6,
    button-color : #292929,
    shadow-color : #e7e7e7,
    input-background : #e7e7e7,
    dropdown-background : #ffffff,
    text-color : rgb(71, 71, 71),
    text-color-secondary : #bdbdbd,
    text-color-terciary : rgb(195, 195, 195),
    text-color-inverted : white,
    grey-color : rgb(129, 129, 129),
    color-placeholder : rgb(129, 129, 129),
    border-color : #e4e4e4,
    box-background : #fafafb,

    wave-color : #d5d5d5,
    wave-color-progress : #6778ff,
  ),
  dark: (
    color-mode : "dark",
    main-font : $main-font,
    bold-font : $bold-font,
    title-font : $title-font,
    primary-color : $primary-color,
    secondary-color : $secondary-color,
    red-color : $red-color,
    third-color : $third-color,
    border-radius : $border-radius,
    /* Dark mode */
    dark-color : #ededed,
    background-color : #1f1f1f,

    background-color-secondary : #ececec,
    background-color-inverse : #e8e8e8,
    background-color-inverse-2 : #3b3b3b,
    background-color-grey : #868686,
    background-color-grey-2 : #1e1e1e,
    background-color-grey-3 : #1d1d1d,

    button-color : #313131,
    shadow-color : #141414,
    input-background : #323232,
    dropdown-background : #333333,

    text-color : #f7f7f7,
    text-color-secondary : #d9d9d9,
    text-color-terciary : #a6a6a6,
    text-color-inverted : #e8e8e8,
    grey-color : #cccccc,

    color-placeholder : #818181,

    border-color : #3b3b3b,
    box-background : #232323,

    wave-color : #414141,
    wave-color-progress : #6778ff,
  )
);

// Define default theme (light) variables globally
@each $color, $value in map-get($themes, 'dark') {
  :root {
    --#{$color}: #{$value};
  }
}

// Define dark theme variables under a specific class
.theme-dark {
  @each $color, $value in map-get($themes, 'light') {
    --#{$color}: #{$value};
  }
}
