@import '../../../index.scss';


.banner__content{
    text-align: left;
}

.banner{
    padding : 20px;
    height: auto;
}

.banner__content{
    display: flex;
    height: 100%;
    padding : 10px;
    width: -webkit-fill-available;
    flex-direction: column;
}

.box-container{
    border : 1px solid var(--border-color);
    border-radius: $border-radius;
    padding : 20px;
}

.container-action{
    display: flex;
    height: 100%;
    flex-direction: row;
}

.action-container-home{
    display: inline-block;
    align-self: flex-end;
    width: auto;
}