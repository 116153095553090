@import '../../../../index.scss';

.normal-container{
    background-color: var(--background-color);
    padding: 10px 20px;
    border-radius: 5px;
    border : 1px solid var(--border-color);
    width: auto;
    float: inline-start;
    margin: 10px 10px;
    height: fit-content;
}

.subscription-name{
    width: max-content;
}

.fifty-container{
    display: grid;
    padding-left : 20px;
    padding-right : 20px;
    height: 100%;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 1200px) {
        grid-template-columns: 100% !important;
    }
}

.sub-cont{

        img{
            border : 1px solid var(--border-color);
            border-radius: 5px;
            margin-right: 15px !important;
    }

}

.div-upgrade{
    width: 100%;
    text-align: right;

    a{
        float: inline-end;
    }
}

.subs-box-item{
    display: grid;
    grid-template-rows: auto auto;

    img{
        width: 50px;
        margin-right: 10px;
        height: 50px;
    }
}

.fifty{
    display: grid;
    grid-template-columns: 50% 50%;
}


.seventy{
    display: grid;
    grid-template-columns: 70% 30% !important;
}

.container-items{
    display: block;
    position: relative;
}

.container-box{
    border-top : 1px solid var(--background-color-grey);
    margin-top : 10px;
    padding-top : 10px;
}