@import '../../../../../colors.scss';


.text-grey-light{
    color : var(--background-color-grey);
}

.countdown-box{
    background-color : var(--background-color);
    border-radius: $border-radius;
    border : 1px solid $primary-color;
    padding: 10px 20px;
    width: 350px;
    .text{
        color : var(--dark-color);
    }

    .text-primary{
        color : $primary-color;
    }
}

.text-primary{
    color : $primary-color;
}


.title-mobile{
    @media screen and (max-width: 768px) {
        margin: auto;
        width: 90%;
    }
}

.container-promo{

    .text-greyish{
        color : var(--background-color-grey)
    }

    .container-2{
        display: grid;
        max-width: 600px;
        margin: auto;
        grid-template-columns: 50% 50%;

        @media screen and (max-width: 768px){
            grid-template-columns: 50% 50%;
        }
    }

    .text-primary{
        margin: auto;
}

    .outline-primary{
        background-color : var(--primary-color);
    background-size: 400%;
    animation: animate 5s linear infinite;
        border-radius: $border-radius;


        .text-grey{
            color: #e0e0e0 !important;
        }
        .text-primary{
            color : var(--background-color) !important;
        }
    }

    .container-outline{
        max-width: 600px;
        width: 90%;
        margin: 10px auto;
    }

    h1{
        margin-top : 0px;
        max-width: 600px !important;
            margin-bottom : 10px;
    }

    .margin-container{
        @media screen and (max-width: 768px) {
            margin-top : 10px !important;
        }
    }

    .text-grey-small{
        color : var(--background-color-grey);
    }

    .text-primary, .text, .text-red{
        max-width : 80%;
        margin-bottom : 0px;
        margin-top  :0px;

    }

    .text-secondary{
        margin-top : 20px;
        margin-bottom : 20px;

        @media screen and (max-width: 768px) {
            margin-top : 40px !important;
        }
    }


    .marquee-container{
        margin-top : 10px;
    }

    .down-text{
        margin-top : 10px;
    }

    .btn-animation{
        margin : auto;
        margin-top : 10px;
        padding : 10px 20px !important;
        width: 350px !important;
    }

}
