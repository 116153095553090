@import '../../../index.scss';

.MuiTab-root{
    font-family: $main-font !important; 
    text-transform: capitalize !important;
    border-radius: 5px !important;
}


.tab-custom{
    padding: 0px 10px;
    min-width: 90px;
    max-height: 40px;
    border-radius: 5px 5px 0px 0px;
    border-top : 1px solid var(--border-color);
    border-left : 1px solid var(--border-color);
    border-top : 1px solid var(--border-color);
    border-right : 1px solid var(--border-color);
}

.tab-active{
    background-color: var(--background-color);
}



.tab-custom-container{
    display: flex;
    position: relative;
    padding : 0px 20px;
}