@import '../../../index.scss';

.list-container{
    width: 100%;
    padding : 0px 20px;
    margin : auto;
}

.list-card{
    display: block;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
}

.list-container-child{
    margin : auto;
}

.padding-list-structure{
}

.pagination-container{
    position: relative;
    width: fit-content;
    margin : auto;
    text-align: center;
    margin-top: 10px;
}

.amounts-container{
    padding: 0px 20px;
    color: var(--text-color-secondary);
    padding-bottom: 15px;
}