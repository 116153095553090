.audio-container {
    border: 1px solid #3b3b3b;
    width: 60%;
    border-radius: 10px;
    padding: 10px;
}

.play-button-container {
    display: flex;
    align-items: center;
    gap: 15px;
}