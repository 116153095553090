@import '../../../index.scss';

.bread-container{
    display: inline-flex;
    position: relative;
    padding-left: 20px;
    margin: 0;  
    margin-bottom: 10px;
    margin-top : 10px;
    height: min-content;
    min-height: 20px;

    span{
        margin : 0px 2px;
    }

    @media screen and (max-width: 450px){
        display: none;
    }
}


.text-grey{
    span{
    color :  var(--grey-color);
    }
}

.text-primary{
    span{
        color : $primary-color;
    }
}