@import '../../../index.scss';

.list-chart-container{
    padding: 20px 0px;

}
.list-chart-item{
    width: 100%;
    padding : 2px 0px;
    border-bottom : 1px solid var(--border-color);
    display: inline-flex;
    img{
        border-radius: $border-radius;
        margin-right: 20px;
        width: 50px;
    }
}

.position-item{
    width: 20px;
    align-self: center;
    text-align: center;
    align-items: center;
    img{
        width: 20px;    
        border-radius: $border-radius;
        margin-right: 10px;
    }

}

.list-chart-item-info{
    padding-top : 5px;
    margin-left: 20px;

    @media screen and (max-width: 920px) {
        overflow: hidden;
        max-width: 100%;
        height: 50px;
    }
}

.list-chart{
    margin-top : 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;

    /* Mobile */
    @media screen and (max-width: 450px) {
    grid-template-columns: auto;
    }
    .list-chart-item{

    }
}

.list-chart-grid{
    width: 100%;
    display: inherit;
}