@import '../../../index.scss';

.inputTextDiv{
    width: 100%;
    margin : 10px auto;

    input{
        width: 100%;
        border-radius: 5px;
        padding :5px 10px;
        font-size: 16px;
        background-color: var(--background-color);
        border : 1px solid var(--border-color);
        margin-bottom : 5px;

        
    }

    h5{
        padding: 5px;
        color : var(--text-color-secondary);
    }
}

.input-error{
    display: block;
    position: relative;
    color : $red-color !important;
}


.error-box{
    border : 1px solid $red-color !important
}

.error-text{
    color :$red-color !important
}

