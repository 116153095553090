@import '../../../../index.scss';

.signup-grid{
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    height: 100vh;

    /* Tablet & Small PC */
   @media screen and (max-width: 1200px) {
    grid-template-columns: 100%;
    }
}

.thank-you-grid{
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    height: 100vh;

    /* Tablet & Small PC */
   @media screen and (max-width: 1200px) {
    grid-template-columns: 100%;
    }
}

.btn-disabled{
  opacity : 0.8 !important;
}
.signup-container{
    display: grid;
    grid-template-rows: auto auto;
}
.signup-art{
    background-color: transparent;
    /* Use a flexbox layout */
	display: flex;
	
	/* Make a horizontal flexbox (the default) */
	flex-direction: row;
	
	/* The important part: vertically center the items */
	align-items: center;

  /* Tablet & Small PC */
   @media screen and (max-width: 1200px) {
    display: none;
    }
}

.goToLoginContainer{
  cursor: pointer;
  margin-top : 20px;
}

.gradient-background {
    height: 100vh;
    top: 0;
    position: absolute;
    opacity: 0.2;
    width: 100%;
    background: linear-gradient(62deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    animation: gradient 15s ease infinite; 
    background-size: 400% 400%;
  
}

.text-container-signup{
    padding: 100px;
    margin : auto;
    width: auto;

}

.loading-gif{
  width: 25px !important;
}

.text-container-sign{
    padding-top : 10px;
    margin : auto;
    max-width: 500px;
    width: auto;
    img{
        width: 120px;
        margin : auto;
    }
  /* Mobile */
  @media screen and (max-width: 1200px) {
      width: 90%;
      margin-top: -50px !important;
      margin: auto;

img{
        width: 70px;
        margin : auto;
    }
    }
    
}

@-webkit-keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
@keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}



.google-division{
  border-bottom : 1px solid var(--border-color);
}

