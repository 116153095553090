@import '../../../index.scss';

.top-bar{
    background-color: $primary-color;
    display: grid;
    text-align: center;
    padding: 5px 10px;
    height: 35px;
    h3{
        color: var(--background-color);
        font-size: 16px;
        text-align: center;
    }
}