@import '../../../../index.scss';

.selectInputs{
    width: auto;
}

.container-multiSelect{
    margin-right: 10px;
}

.selected-button{
    width: 100%;
    display: flex;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border : 1px solid var(--border-color);
    background-color:  var(--dropdown-background);
    h1,h2,h3,h4,h5,h6,label,span{
        color :var(--text-color) ;
    }
}