@import '../../../index.scss';

.credits-container{
    color : $primary-color !important;
    display: inline-flex;
    svg{

        transform: scale(0.7);    
    }
    h4{
        margin-top : 2px;
        color : $primary-color !important;
    }
}

.switch-container{
    width: 35px;
    height: 35px;
    padding-top: 10px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    position: relative;
    margin: 0px 0px;

    svg{
        margin: auto;
    }
}