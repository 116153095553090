@import '../../../index.scss';

.card-expanded{
    width: auto;
    height: auto;
    position: fixed;
    top: 50%;
    cursor: none;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    z-index: 2;
}



.card-container-absolute{
    position:absolute;
    top:0;right:0;left:0;bottom:0;
    background-color:rgba(0,0,0,0.8);
    z-index:2;
}

.card-container{
    background-color: #3c3c3c;
    width: inherit;
    height: inherit;
    box-shadow: 1px 10px 100px 1px var(--shadow-color);
    border-radius: 20px;
    padding : 0px;
    width: 200px;
    cursor:pointer;
    margin: 10px;
    border : 0px;
    transition: transform 0.5s;
    &:hover { transform: scale(1.05); opacity: 80%; }
}

canvas{
    margin-top: -15px;
}

.upper-card{
    background-color: black;
    border-radius: 20px;
}

.down-card{
    padding : 10px;
}

.container-card-absolute-max{
    position: relative;
    padding: 10px;
}

.credits{
    display: block;
    margin-top: -60px;
    margin-left: 130px;
    font-size: small;
    position: absolute;
}

.btn-card{
    position: absolute;
    bottom: 20px;
    width: 80% !important;
    margin-left: 10% !important;
    z-index: 2;
}

.attributes-container{
    background-color: rgb(23, 23, 23);
    padding: 20px;
    border-radius: 20px;
}

.economic-containers{
    padding: 10px;
}