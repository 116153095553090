@import '../../../index.scss';

div#timeline1 {
    width: 100%;
    margin: auto;
    position: absolute;
    background: var(--background-color);
    height : 2px;
    display: block;
    top : 0;
}

div#timeline2 {
    width: 100%;
    margin: auto;
    position: absolute;
    background: $primary-color;
    height : 2px;
    display: block;
    top : 0;
}

div#seekObj1 {
    width: 10px;
    z-index: 3;
cursor: pointer;
    height: 10px;
    background: $primary-color;
    margin-bottom : 5px;
    border-radius: 50%;
    position: absolute;
    margin-top : -4px
}

.container-audio-player{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 2px;
}

ul {
    list-style-type: none;
}

li {
    list-style-type: none;
}
