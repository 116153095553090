.wave-absolute{
   width: 100%;
    margin-left: 5%;
    position: relative;
    cursor: pointer;
    display: block;
    position: relative;
    height: 50px;
    padding-bottom: 10px;
    overflow: hidden;
}

.waveform-small{
    width: 200px;
    height: 40px;
    top: -10px;
    display: block;
    overflow: hidden;
    position: relative;
}


.waveform{
    width: 100%;
    display: block;
    position: relative;
    height: 50px;
}


canvas{
    display: block;
}