@import '../../../index.scss';

.button{
    width : 120px;
    background: $primary-color;
    text-align: center;
    opacity : 300ms ease-in-out 0s;
    border-radius: 5px;
    border: 0px;
    cursor:pointer;
    transition: box-shadow var(--duration-short) ease;
    -webkit-appearance: none;
    appearance: none;
    margin : 10px 20px;
}
