@import '../../../../colors.scss';



.landing-page{
    .btn-animation{
        margin-top : 20px !important;
        margin: auto;
        width: max-content !important;
        padding: 10px 20px !important;

    }


    @media screen and (max-width: 450px) {
        .pack-container{
            grid-template-rows: 150px auto auto;
            width: 250px !important;
            height: 300px;
            margin: 5px;
            height: auto;
        }
        .pack-container-outer{
                border: none !important;

    }
    }

}
.main-container{
    margin-top : 40px;

    @media screen and (max-width: 450px){
        margin-top : 10px;
    }

    h1{
        max-width: 600px;
        margin: 0px auto;
        margin-top : 50px;
        margin-bottom : 0px;
        @media screen and (max-width: 450px) {
            max-width: 80%;
        }
    }
    .text{
        max-width: 400px;
        margin: 0px auto;
        margin-top : 20px;
    }

    button{
        margin-top : 50px;
    }
}

.number-container{
    margin : 10px;
    width: 150px;
}

.container-numbers{
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-top : 30px;
    .number{
        font-weight: 600;
        color: $primary-color;
    }
    .text{
        color: var(--text-color-secondary);
    }
}

.tag-major{
    background-color: #3dff42;
    border-radius: 20px;
    width: max-content;
    padding: 10px 20px;
    margin: auto;
    color: var(--text-color-inverted);
}

.margin-text-landing-main{
    margin : 40px auto !important;
}

.text-main{
    .flex-container{
        width: fit-content;
        margin: auto;
    }
    .tag-outline{
        width: fit-content;
        color: var(--text-color-secondary);
    }
}