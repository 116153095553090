@import '../../../../colors.scss';


.background-gradient{
background: linear-gradient(62deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    opacity: 0.1;
    position: absolute;
    bottom: 0;
    height: 400px;
    width: 100%;
    /* position: relative; */
    display: block;
    z-index: -1;
}

.text-mobile-center{
    @media screen and (max-width: 768px){
        text-align: center;
        
    }
}

.text-community{
    color : var(--grey-color)  !important;
}

.thirdpage{
    padding: 50px 0px;
    margin : auto;
    background-color: transparent;
    .text-primary{
        color: var(--background-color);
    }

    .container-communtiy-ico{
        margin-left: 40px;
    }

  
    .text-container{
        
        .text-grey{
            color : var(--grey-color);
        }

    }
    .btn-primary{
        background-color: $primary-color;
        margin : auto;
        margin-top : 30px;
        padding : 10px 20px;

        .text-primary{
            color: var(--background-color) !important;
        }
    }
}

.commu-container{
    width: 200px;
    margin: 20px auto;
    padding-bottom : 20px;
    align-items: center;

    .container-communtiy-ico{
        margin-left: 40px;
        margin-bottom: 10px;
    }
}


.marquee-list{
    margin-top : 30px;
}