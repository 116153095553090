@import '../../../index.scss';

.tags-container{
    margin: 0;
    h4{
        background-color: var(--background-color-grey);
        border-radius: 5px;
        padding: 1px 10px;
        color:  var(--text-color); 
        display: inline-block;
    } 
}

.container-tags{
    margin-left : 20px;
    margin-top: 0px;
    margin-bottom : 10px;
    h4{
        font-size: 15px;
    }
}